<template>
  <div>
    <ManageOfficer />
  </div>
</template>
<script>
import ManageOfficer from "@/components/Officer/ManageOfficer";
export default {
  components: {
    ManageOfficer,
  },
  created() {},
};
</script>
c
